<template>
  <div>
    <div>
          <div>
            <b-link :to="{ name: 'RegDecompWorkspace', params: { id: regulation.id }}" class="mr-2"><strong>{{regulation.name_short}}</strong></b-link>
            <b-button class="mr-2" variant="outline-primary" size="sm" :to="{ name: 'RegDecompRegulation', params: { id: regulation.id }}">static</b-button>
            <b-button variant="outline-primary" size="sm" v-if="permission.add" :to="{ name: 'FormEdit', params: { id: regulation.id, model: 'regulation' }}">edit</b-button>
          </div>
          <div>{{ regulation.name_long }}</div>
          <div><strong>{{ regulation.regulator.name }}</strong><span v-if="regulation.regulator.comments"> - {{ regulation.regulator.comments }}</span></div>
          <div class="mb-2" v-if="regulation.regtopics.length > 0">
            <strong>Reg topics: </strong>
            <span v-for="topic in regulation.regtopics" :key="'topic' + topic.id">
              <router-link :to="{ name: 'Topic', params: { id: topic.id}}" >{{topic.name}}</router-link> |
            </span>
          </div>
          <div class="mb-2" v-if="regulation.tags.length > 0">
            <strong>Tags: </strong>
            <span v-for="tag in regulation.tags" :key="'tag' + tag.id">
              <router-link :to="{ name: 'NewsTag', params: { id: tag.id}}" >{{ tag.name }} | </router-link>
            </span>
          </div>
          <div><small>KPMG id: {{ regulation.id }} | source id: {{ regulation.code }} | in force: {{ moment(regulation.date_in_force).format('YYYY-MM-DD') }} | created: {{ moment(regulation.created_at).format('YYYY-MM-DD') }} by: {{regulation.created_by}}</small></div>
          <div><small><b-link :href="regulation.url">{{regulation.url}}</b-link></small></div>
          <div v-if="regulation.users.length>0">
            <small>
            restricted:
            <span v-for="user in regulation.users" :key="regulation.id + user.username">{{user.username}} | </span>
            </small>
          </div>
          <div v-if="permission.add"><small>{{regulation.uuid}}</small></div>
    </div>
  </div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'

export default {
  name: 'RegulationTypes',
  props: ['regulation'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('regulation').granted
    this.permission.read = ac.can(this.user.acgroups).readAny('regulation').granted
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
  },
  data () {
    return {
      moment: moment,
      permission: {
        add: false,
        delete: false,
        read: false
      }
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
