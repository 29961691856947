<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <div>
                  <div v-if="!loading">
                    <div>
                      <b-link :to="{ name: 'RegDecompWorkspace', params: { id: regulation.id }}" class="mr-2"><strong>{{regulation.name_short}}</strong></b-link>
                      <b-button variant="outline-primary" size="sm" v-if="permission.add" :to="{ name: 'FormEdit', params: { id: regulation.id, model: 'regulation' }}">edit</b-button>
                    </div>
                    <div>{{ regulation.name_long }}</div>
                    <div><strong>{{ regulation.regulator.name }}</strong><span v-if="regulation.regulator.comments"> - {{ regulation.regulator.comments }}</span></div>
                    <div class="mb-2" v-if="regulation.regtopics.length > 0">
                      <strong>Reg topics: </strong>
                      <span v-for="topic in regulation.regtopics" :key="'topic' + topic.id">
                        <router-link :to="{ name: 'Topic', params: { id: topic.id}}" >{{topic.name}}</router-link> |
                      </span>
                    </div>
                    <div class="mb-2" v-if="regulation.tags.length > 0">
                      <strong>Tags: </strong>
                      <span v-for="tag in regulation.tags" :key="'tag' + tag.id">
                        <router-link :to="{ name: 'NewsTag', params: { id: tag.id}}" >{{ tag.name }} | </router-link>
                      </span>
                    </div>
                    <div><small>KPMG id: {{ regulation.id }} | source id: {{ regulation.code }} | in force: {{ moment(regulation.date_in_force).format('YYYY-MM-DD') }} | created: {{ moment(regulation.created_at).format('YYYY-MM-DD') }} by: {{regulation.created_by}}</small></div>
                    <div><small><b-link :href="regulation.url">{{regulation.url}}</b-link></small></div>
                    <div v-if="regulation.users.length>0">
                      <small>
                      restricted:
                      <span v-for="user in regulation.users" :key="regulation.id + user.username">{{user.username}} | </span>
                      </small>
                    </div>
                    <div v-if="permission.add"><small>{{regulation.uuid}}</small></div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'

export default {
  name: 'Regulation',
  components: {
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('regulation').granted
    this.permission.read = ac.can(this.user.acgroups).readAny('regulation').granted
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      moment: moment,
      regulation: {},
      permission: {
        add: false,
        delete: false,
        read: false
      }
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.regulation = await this.$Amplify.API.get('cosmos', `/regulation/${this.$route.params.id}`)
        document.title = this.regulation.name_short
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style>
</style>
