<template>
  <b-container class="bg-white" v-if="permission.read">
    <b-row v-if="!loadingRegulations && show.all" class="mb-4">
      <b-col>
        <h2 class="mb-4">Regulations <b-button @click="regulationAdd" v-if="permission.add">add</b-button></h2>
        <b-input-group class="mb-2">
          <b-form-input v-model="searchFilter" placeholder="Search......" />
        </b-input-group>
        <b-form inline class="mb-2">
          <b-form-select class="mr-2" v-model="regtopicSelected" :options="regtopicOptionsDynamic"></b-form-select>
        </b-form>
        <b-form inline class="mb-5">
          <b-form-select class="mr-2" v-model="countrySelected" :options="countryOptionsDynamic"></b-form-select>
          <b-form-select class="mr-2" v-model="regulatorSelected" :options="regulatorOptionsDynamic"></b-form-select>
          <b-form-select class="mr-2" v-model="tagSelected" :options="tagOptionsDynamic"></b-form-select>
          <strong class="mr-2">showing {{regulations.length}} of total {{regulationsRaw.length}} | </strong>
          <b-button variant="outline-primary" @click="filterReset" class="mr-2">reset filters</b-button>
        </b-form>
        <div class="mb-3" v-for="regulation in regulations" :key="'regulation-id' + regulation.id">
          <regulation :regulation="regulation">
          </regulation>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingRegulations" class="mb-4">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permission && show.add">
      <b-col>
          <h5>Adding new regulation</h5>
          <b-form-group id="input-name-short-group" label="Short name" label-for="input-name-short">
            <b-form-input id="input-name-short" v-model="form.name_short" placeholder="Short name"></b-form-input>
          </b-form-group>
          <b-form-group id="input-name-long-group" label="Long name" label-for="input-name-long">
            <b-form-input id="input-name-long" v-model="form.name_long" placeholder="Long name"></b-form-input>
          </b-form-group>
          <b-form-group id="input-code-group" label="Code" label-for="input-code">
            <b-form-input id="input-code" v-model="form.code" placeholder="Code"></b-form-input>
          </b-form-group>
          <b-form-group id="input-url-group" label="Link" label-for="input-url">
            <b-form-input id="input-url" v-model="form.url" placeholder="https://www.example.com"></b-form-input>
          </b-form-group>
          <b-form-group id="input-regulator-group" label="Regulator" label-for="input-regulator">
            <b-form-select id="input-regulator" v-model="form.regulator_id" :options="regulatorOptions"></b-form-select>
          </b-form-group>
          <b-form-group id="input-counter-group" label="Country" label-for="input-countrt">
            <b-form-select id="input-country" v-model="form.country_code" :options="countryOptions"></b-form-select>
          </b-form-group>
          <b-form-group id="input-date-in-force-group" label="Date in force" label-for="input-date-in-force">
            <b-form-datepicker id="input-date-in-force" v-model="form.date_in_force" class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-form-group id="input-date-last-amended-group" label="Date last amended" label-for="input-date-last-amended">
            <b-form-datepicker id="input-date-last-amended" v-model="form.date_last_amended" class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-form-group id="input-type-group" label="Type" label-for="input-type">
            <b-form-select id="input-type" v-model="form.type_id" :options="typeOptions"></b-form-select>
          </b-form-group>
          <b-form-group id="input-status-group" label="Status" label-for="input-status">
            <b-form-select id="input-status" v-model="form.status_id" :options="statusOptions"></b-form-select>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox id="checkbox-pdf" v-model="form.pdf" name="checkbox-pdf" :value="true" :unchecked-value="false">
              PDF file
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox id="checkbox-html" v-model="form.static" name="checkbox-html" :value="true" :unchecked-value="false">
              HTML file
            </b-form-checkbox>
          </b-form-group>
          <b-form-group v-if="this.form.pdf" id="input-uuid-group" label="UUID" label-for="input-uuid">
            <b-form-input id="input-uuid" v-model="form.uuid" placeholder="UUID"></b-form-input>
          </b-form-group>
          <b-form-group v-if="!this.form.pdf" id="input-body-group" label="Short name" label-for="input-body">
            <b-form-textarea id="input-body" v-model="form.body" placeholder="html content" rows="30"></b-form-textarea>
          </b-form-group>
          <b-button class="mr-4" v-if="validate" variant="outline-primary" @click="regulationSave">Save</b-button>
          <b-button variant="outline-primary" @click="regulationAddCancel">Cancel</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import countries from '@/libs/countries'
import moment from 'moment'
import Regulation from '@/components/Regulation.vue'
// import Dashboard from '../../components/workflow/article/Dashboard.vue'

export default {
  name: 'RegulationTypes',
  components: {
    Regulation
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    countryOptionsDynamic: function () {
      let regulations = []
      let result = []
      if (Array.isArray(this.regulations) && this.regulations.length > 0) {
        regulations = this.regulations
        regulations.forEach(x => {
          const row = { value: x.country_code, text: countries.find(y => y.alpha2 === x.country_code).country }
          result.push(row)
        })
        result = _.uniqBy(result, 'value')
        result = _.sortBy(result, 'text')
        result = _.concat([{ value: null, text: 'all countries' }], result)
      }
      return result
    },
    regtopicOptionsDynamic: function () {
      let regulations = []
      if (Array.isArray(this.regulations)) {
        regulations = this.regulations
      }
      let result = []
      regulations.forEach(x => {
        if (x.regtopics.length > 0) {
          x.regtopics.forEach(y => {
            const row = { value: y.id, text: y.name }
            result.push(row)
          })
        }
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result = _.concat([{ value: null, text: 'all topics' }], result)
      return result
    },
    regulations: function () {
      let results = this.regulationsRaw
      if (this.countrySelected) {
        results = results.filter(x => {
          return x.country_code === this.countrySelected
        })
      }
      if (this.regtopicSelected) {
        results = _.filter(results, x => {
          const exists = _.find(x.regtopics, ['id', this.regtopicSelected])
          if (exists) {
            return true
          } else {
            return false
          }
        })
      }
      if (this.regulatorSelected) {
        results = results.filter(x => {
          return x.regulator.id === parseInt(this.regulatorSelected)
        })
      }
      if (this.tagSelected) {
        results = _.filter(results, x => {
          const exists = _.find(x.tags, ['id', this.tagSelected])
          if (exists) {
            return true
          } else {
            return false
          }
        })
      }
      results = results.filter(item => {
        let regtopics = ''
        if (item.regtopics.length > 0) {
          item.regtopics.forEach(x => {
            regtopics = regtopics + ' ' + x.name
          })
        }
        let tags = ''
        if (item.tags.length > 0) {
          item.tags.forEach(x => {
            tags = tags + ' ' + x.name
          })
        }
        const term = item.name_short + ' ' + item.name_long + ' ' + item.code + ' ' + item.regulator.name + ' ' + item.url + regtopics + tags
        return term.toUpperCase().match(this.searchFilter.toUpperCase())
      })
      return results
    },
    regulatorOptionsDynamic: function () {
      let regulations = []
      if (Array.isArray(this.regulations)) {
        regulations = this.regulations
      }
      let result = []
      regulations.forEach(x => {
        const row = { value: x.regulator.id, text: x.regulator.name }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result = _.concat([{ value: null, text: 'all regulators' }], result)
      return result
    },
    tagOptionsDynamic: function () {
      let regulations = []
      if (Array.isArray(this.regulations)) {
        regulations = this.regulations
      }
      let result = []
      regulations.forEach(x => {
        if (x.tags.length > 0) {
          x.tags.forEach(y => {
            const row = { value: y.id, text: y.name }
            result.push(row)
          })
        }
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result = _.concat([{ value: null, text: 'all tags' }], result)
      return result
    },
    validate: function () {
      let nameShort = false
      if (this.form.name_short.length > 2) {
        nameShort = true
      }
      let nameLong = false
      if (this.form.name_long.length > 5) {
        nameLong = true
      }
      let code = false
      if (this.form.code.length > 2) {
        code = true
      }
      let url = false
      if (this.form.url.length > 5) {
        url = true
      }
      let regulator = true
      if (this.form.regulator_id === null) {
        regulator = false
      }
      let country = true
      if (this.form.country_code === null) {
        country = false
      }
      let dateForce = true
      if (this.form.date_in_force === null) {
        dateForce = false
      }
      let dateAmended = true
      if (this.form.date_last_amended === null) {
        dateAmended = false
      }
      let type = true
      if (this.form.type_id === null) {
        type = false
      }
      let status = true
      if (this.form.status_id === null) {
        status = false
      }
      let body = true
      if (this.form.body.length < 500 && this.form.pdf === false && this.form.static === false) {
        body = false
      }
      return nameShort && nameLong && code && url && regulator && country && dateForce && dateAmended && type && status && body
    }
  },
  created: async function () {
    document.title = "KPMG Regulatory Horizon: Regulations"
    this.$store.commit('setBrand', 'Obligations')
    this.$stat.log({ page: 'regulations', action: 'open regulations' })
    this.formSet()
    this.permission.read = ac.can(this.user.acgroups).readAny('regulation').granted
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
    const countryOptions = []
    countryOptions.push({ value: null, text: 'Select country' })
    countries.forEach(country => {
      countryOptions.push({ value: country.alpha2, text: country.country })
    })
    this.countryOptions = countryOptions
    const regulators = await this.$Amplify.API.get('cosmos', '/standard/regulator')
    const regulatorOptions = []
    regulatorOptions.push({ value: null, text: 'Select reguator' })
    regulators.forEach(regulator => {
      regulatorOptions.push({ value: regulator.id, text: regulator.name })
    })
    this.regulatorOptions = regulatorOptions
    const statuses = await this.$Amplify.API.get('cosmos', '/standard/regulationstatus')
    const statusOptions = []
    statusOptions.push({ value: null, text: 'Select status' })
    statuses.forEach(status => {
      statusOptions.push({ value: status.id, text: status.name })
    })
    this.statusOptions = statusOptions
    const types = await this.$Amplify.API.get('cosmos', '/standard/regulationtype')
    const typeOptions = []
    typeOptions.push({ value: null, text: 'Select type' })
    types.forEach(type => {
      typeOptions.push({ value: type.id, text: type.name })
    })
    this.typeOptions = typeOptions
    this.regulationsLoad()
  },
  data () {
    return {
      countryOptions: [],
      countrySelected: null,
      form: {},
      loadingRegulations: true,
      moment: moment,
      permission: {
        add: false,
        read: false
      },
      regtopicSelected: null,
      regulation: null,
      regulationsRaw: [],
      regulatorOptions: [],
      regulatorSelected: null,
      statusOptions: [],
      tagSelected: null,
      typeOptions: [],
      searchFilter: '',
      show: {
        add: false,
        all: true,
        users: false
      },
      statuses: [],
      usersLoaded: [],
      usersLoading: false,
      usersMatched: [],
      userSearchInput: ''
    }
  },
  methods: {
    filterReset: function () {
      this.countrySelected = null
      this.searchFilter = ''
      this.regtopicSelected = null
      this.regulatorSelected = null
      this.tagSelected = null
    },
    formSet: function () {
      this.form = {
        body: '',
        code: '',
        country_code: null,
        date_in_force: null,
        date_last_amended: null,
        name_short: '',
        name_long: '',
        pdf: false,
        regulator_id: null,
        static: false,
        status_id: null,
        type_id: null,
        url: '',
        uuid: null
      }
    },
    regulationAdd: function () {
      this.show.add = true
      this.show.all = false
    },
    regulationAddCancel: function () {
      this.show.add = false
      this.show.all = true
      this.formSet()
    },
    regulationSave: async function () {
      try {
        this.form.created_by = this.user.username
        const params = {
          body: this.form
        }
        await this.$Amplify.API.put('cosmos', '/standard/regulation', params)
        this.formSet()
        this.regulationsLoad()
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    regulationsLoad: async function () {
      this.loadingRegulations = true
      try {
        this.regulationsRaw = await this.$Amplify.API.get('cosmos', '/regulations')
        this.loadingRegulations = false
      } catch (e) {
        this.$logger.warn('loading regulations error ', e)
      }
    },
    userAssign: async function (username) {
      this.usersLoading = true
      try {
        const params = {
          body: {
            payload: {
              regulation_id: this.regulation.id,
              username: username,
              created_by: this.user.username
            }
          }
        }
        const userAssigned = await this.$Amplify.API.put('cosmos', '/regulation-user', params)
        const users = this.regulation.users
        users.push(userAssigned)
        this.regulation.users = users
        this.userSearchInput = ''
        this.$stat.log({ page: 'regulations', action: 'assign user', model: 'regulation', model_id: this.regulation.id, payload: params.body })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.usersLoading = false
    },
    userRemove: async function (id) {
      this.usersLoading = true
      try {
        await this.$Amplify.API.del('cosmos', `/regulation-user/${id}`)
        const users = this.regulation.users
        _.pullAllBy(users, [{ id: id }], 'id')
        this.regulation.users = []
        this.regulation.users = users
        this.$stat.log({ page: 'regulations', action: 'remove user', model: 'regulation', model_id: this.regulation.id })
      } catch (e) {
        this.$logger.warn('assigning user error: ' + e)
      }
      this.usersLoading = false
    }
  },
  watch: {
    userSearchInput: async function () {
      let triggerSearch = false
      if (this.userSearchInput.length >= 2) {
        triggerSearch = true
      }
      if (triggerSearch && this.usersLoaded.length === 0) {
        this.usersLoading = true
        this.usersLoaded = await this.$Amplify.API.get('cosmos', `/users`)
        this.usersLoading = false
      }
      if (triggerSearch) {
        const temp = this.usersLoaded.filter(item => item.id.toUpperCase().includes(this.userSearchInput.toUpperCase()))
        if (this.regulation.users.length > 0) {
          _.pullAllBy(temp, this.regulation.users, 'username')
        }
        this.usersMatched = _.take(temp, 3)
      }
      if (this.userSearchInput.length < 2) {
        this.usersMatched = []
      }
    }
  }
}
</script>

<style>
</style>
